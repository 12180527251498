<!-- @format -->
<template>
  <div>
    <Loading v-show="show" />

    <div class="background-area overlay-dark-fullwidth no-animation">
      <img loading="lazy" alt="" id="ImagesFID" style="width: 100%" />
      <div class="banner-content static-text">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="text-content-wrapper text-center full-width">
                <div class="text-content table-cell">
                  <h1
                    class="title1 cd-headline push text-center border-none mt-40"
                  >
                    <span class="cd-words-wrapper">
                      <b id="postTitleId" class="is-visible"></b>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="contact-form-area section-padding" v-show="showAbout">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-12">
            <div class="contact-text">
              <p
                v-for="about in About"
                :key="about.postID"
                style="background-color: rgb(52, 152, 219) !important"
              >
                <span class="c-icon"><i class="fa fa-chevron-left"></i></span
                ><span class="c-text"
                  ><a
                    href="#"
                    aria-label="title"
                    @click="getAboutById(about.postID)"
                    >{{ about.postTitle }}</a
                  ></span
                >
              </p>
            </div>
          </div>
          <div class="col-lg-9 col-12">
            <div class="course-details-content">
              <div class="single-course-details">
                <div class="row">
                  <div class="col-md-12">
                    <div class="single-item-text">
                      <h5 id="postTitle_"></h5>
                      <p id="postSubTitle_"></p>
                      <p id="postDetial_"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-show="showMessage"
      class="about_area_center about-area_2 newsletter-area_3"
      v-bind:style="{
        'margin-top': '20px',
        background:
          'rgba(0, 0, 0, 0) url(https://api2.yuniv.net:444/Images/post/' +
          ImageMess +
          ')',
      }"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="about-container">
              <h3>{{ TitleMess }}</h3>
              <p>
                {{ SubMess }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="event-area section-padding bg-white" v-show="showActivity">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title-wrapper">
              <div class="section-title">
                <h3 style="margin-bottom: 24px">{{ CenterDept }}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            style="margin-top: 24px"
            v-for="event in Events"
            :key="event.postId"
            class="col-lg-4 col-md-6 col-12"
          >
            <div class="single-event-item">
              <div class="single-event-image">
                <a>
                  <img
                    loading="lazy"
                    alt=""
                    v-bind:src="
                      'https://api2.yuniv.net:444/images/post/' +
                      event.postImage
                    "
                  />
                </a>
              </div>
              <div class="single-event-text">
                <h6>
                  <a
                    aria-label="tilte"
                    style="line-height: 2; text-align: justify"
                    >{{ event.postTitle }}
                  </a>
                </h6>
                <!-- event.postSubTitle.substring(0, 110) + "..."  -->
                <p style="text-align: justify">
                  {{ event.postSubTitle }}
                </p>
                <router-link
                  href="#"
                  aria-label="title"
                  class="button-default"
                  :to="{
                    name: 'DepartmentDetails-with-BrnId-And-Type-And-id',
                    params: {
                      type: 'Univ.Centers.Activity',
                      id: event.postID,
                      BrnId: event.brnID,
                    },
                  }"
                >
                  {{ Detials }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-sm-12 text-center">
          <router-link
            href="#"
            aria-label="title"
            :to="{
              name: 'Department-with-BrnId-And-Type',
              params: {
                type: 'Univ.Centers.Activity',
                BrnId: $route.params.id,
              },
            }"
            class="button-default button-large"
          >
            {{ BtnMore }} <i class="fa fa-chevron-left"></i
          ></router-link>
        </div>
      </div>
    </div>

    <div
      v-show="showNews"
      class="latest-area section-padding"
      style="background-color: rgb(52, 152, 219); padding: 60px 0 0px"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title-wrapper">
              <div class="section-title">
                <h3 style="margin-bottom: 24px; color: #fff">
                  {{ CenterNews }}
                </h3>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div
            v-for="news in News"
            :key="news.postId"
            class="col-lg-6 col-md-12 col-sm-12 col-xs-12"
          >
            <div class="single-latest-item">
              <div class="single-latest-image">
                <a
                  ><img
                    loading="lazy"
                    class="imagesNews_"
                    v-bind:src="
                      'https://api2.yuniv.net:444/images/post/' + news.postImage
                    "
                    alt=""
                /></a>
              </div>
              <div class="single-latest-text">
                <h6 style="margin-bottom: 20px">
                  <a>{{ news.postTitle }}</a>
                </h6>
                <p>
                  {{ news.postSubTitle }}
                </p>
                <router-link
                  href="#"
                  aria-label="title"
                  class="button-default"
                  :to="{
                    name: 'NewsDetails-with-id-And-Type-And-BrnId',
                    params: {
                      type: 'Univ.Centers.News',
                      id: news.postID,
                      BrnId: news.brnID,
                    },
                  }"
                >
                  {{ Detials }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-sm-12 text-center">
          <router-link
            href="#"
            aria-label="title"
            style="margin: 25px"
            :to="{
              name: 'News-With-Type-And_BrnId',
              params: {
                type: 'Univ.Centers.News',
                BrnId: $route.params.id,
              },
            }"
            class="button-default button-large"
          >
            {{ BtnMore }} <i class="fa fa-chevron-left"></i
          ></router-link>
        </div>
      </div>
    </div>

    <div class="contact-form-area section-padding" v-show="showGallery">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title-wrapper">
              <div class="section-title">
                <h3 style="margin-bottom: 24px">{{ PhotoBrowser }}</h3>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-12">
            <div class="contact-text">
              <p
                id="groupImages"
                v-for="galleryCategory in GalleryCategory"
                :key="galleryCategory.postID"
              >
                <span class="c-icon"><i class="fa fa-folder"></i></span
                ><span class="c-text"
                  ><a
                    aria-label="title"
                    @click="galleries(galleryCategory.postID)"
                    >{{ galleryCategory.postTitle }}</a
                  ></span
                >
              </p>
            </div>
          </div>
          <div class="col-lg-9 col-12">
            <div class="course-details-content">
              <div class="single-course-details">
                <div class="row" id="gelery">
                  <div
                    v-for="gallery in Gallery"
                    :key="gallery.postID"
                    class="col-lg-4 col-md-6 mb-30"
                  >
                    <div class="gallery-img">
                      <img
                        loading="lazy"
                        v-bind:src="
                          'https://api2.yuniv.net:444/images/post/' +
                          gallery.postImage
                        "
                      />
                      <div class="hover-effect">
                        <div class="zoom-icon">
                          <a
                            class="popup-image"
                            :href="
                              'https://api2.yuniv.net:444/images/post/' +
                              gallery.postImage
                            "
                            ><i class="fa fa-search-plus"></i
                          ></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contact-form-area section-padding" v-show="!showGallery"></div>
  </div>
</template>
<script>
import Loading from "./Loading";
import axios from "axios";
import ArJson from "../../public/i18n/Ar.json";
import EnJson from "../../public/i18n/En.json";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      show: true,
      showAbout: true,
      showMessage: true,
      showActivity: true,
      showNews: true,
      showGallery: true,
      About: [],

      Events: [],
      News: [],
      ImageMess: "",
      TitleMess: "",
      SubMess: "",
      GalleryCategory: [],
      Gallery: [],

      //translate
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
      CenterDept: "",
      CenterNews: "",
      PhotoBrowser: "",
      BtnMore: "",
      Detials: "",
    };
  },
  methods: {
    getAboutById(id) {
      var bodyFormData1 = new FormData();
      bodyFormData1.append("check", "getPostById");
      bodyFormData1.append("Lang", localStorage.getItem("lang"));
      bodyFormData1.append("PostId", id);
      bodyFormData1.append("FbrnId", "");
      bodyFormData1.append("Type", "Univ.Centers.About");
      bodyFormData1.append("ParentId", "NTROOT0");
      bodyFormData1.append("PostName", "");
      bodyFormData1.append("Pno", "1");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData1,
      })
        .then(function (response) {
          document.getElementById("postTitle_").innerText =
            response.data[0]["postTitle"];
          document.getElementById("postSubTitle_").innerText =
            response.data[0]["postSubTitle"];
          document.getElementById("postDetial_").innerHTML =
            response.data[0]["postDetails"];
        })
        .catch(function (response) {
          // eslint-disable-next-line no-console
          console.log("error", response);
        });
    },
    galleries(id) {
      var self = this;
      var bodyFormData2 = new FormData();
      bodyFormData2.append("check", "getPostById");
      bodyFormData2.append("Lang", localStorage.getItem("lang"));
      bodyFormData2.append("PostId", "");
      bodyFormData2.append("FbrnId", this.$route.params.id);
      bodyFormData2.append("Type", "Univ.Centers.Gallery");
      bodyFormData2.append("ParentId", id);
      bodyFormData2.append("PostName", "");
      bodyFormData2.append("Pno", "9");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData2,
      })
        .then(function (response) {
          self.Gallery = response.data;
        })
        .catch(function (response) {
          // eslint-disable-next-line no-console
          console.log("error", response);
        });
    },
    getApis() {
      var self = this;
      var ID = this.$route.params.id;

      var bodyFormData3 = new FormData();
      bodyFormData3.append("Lang", localStorage.getItem("lang"));
      bodyFormData3.append("Id", ID);

      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/branches/GetBrnWebSites",
        data: bodyFormData3,
      })
        .then(function (response) {
          // eslint-disable-next-line no-console
          console.log("nada", response);
          var BrnData;
          BrnData = response.data;
          if (BrnData.length != 0) {
            document.getElementById("postTitleId").innerText =
              response.data[0]["brnTitle"];
            document
              .getElementById("ImagesFID")
              .setAttribute(
                "src",
                "https://api2.yuniv.net:444/Images/branches/" +
                  response.data[0]["brnImage"]
              );
          }

          var bodyFormData = new FormData();

          bodyFormData.append("check", "getPost");
          bodyFormData.append("Lang", localStorage.getItem("lang"));
          bodyFormData.append("PostId", "");
          bodyFormData.append("FbrnId", ID);
          bodyFormData.append("Type", "Univ.Centers.About");
          bodyFormData.append("ParentId", "NTROOT0");
          bodyFormData.append("PostName", "");
          bodyFormData.append("Pno", "20");
          axios({
            method: "post",
            url: "https://api2.yuniv.net:444/our_team/getPost",
            data: bodyFormData,
          })
            .then(function (response) {
              self.About = response.data;

              if (self.About.length != 0) {
                self.getAboutById(response.data[0]["postID"]);
              }

              var bodyFormData = new FormData();
              bodyFormData.append("check", "getPost");
              bodyFormData.append("Lang", localStorage.getItem("lang"));
              bodyFormData.append("FbrnId", ID);
              bodyFormData.append("Type", "Univ.Centers.Message");
              bodyFormData.append("ParentId", "NTROOT0");
              bodyFormData.append("PostName", "");
              bodyFormData.append("Pno", "1");
              axios({
                method: "post",
                url: "https://api2.yuniv.net:444/our_team/getPost",
                data: bodyFormData,
              })
                .then(function (response) {
                  var array;
                  array = response.data;

                  if (array.length != 0) {
                    self.showMessage = true;
                    self.ImageMess = response.data[0]["postImage"];
                    self.TitleMess = response.data[0]["postTitle"];
                    self.SubMess = response.data[0]["postSubTitle"];
                  } else self.showMessage = false;

                  var bodyFormData = new FormData();
                  bodyFormData.append("check", "getPost");
                  bodyFormData.append("Lang", localStorage.getItem("lang"));
                  bodyFormData.append("FbrnId", ID);
                  bodyFormData.append("Type", "Univ.Centers.Activity");
                  bodyFormData.append("ParentId", "NTROOT0");
                  bodyFormData.append("PostName", "");
                  bodyFormData.append("Pno", "-1");
                  axios({
                    method: "post",
                    url: "https://api2.yuniv.net:444/our_team/getPost",
                    data: bodyFormData,
                  })
                    .then(function (response) {
                      self.Events = response.data;
                      if (self.Events.length != 0) {
                        self.showActivity = true;
                      } else {
                        self.showActivity = false;
                      }
                      var bodyFormData = new FormData();

                      bodyFormData.append("check", "getPost");
                      bodyFormData.append("Lang", localStorage.getItem("lang"));
                      bodyFormData.append("FbrnId", ID);
                      bodyFormData.append("Type", "Univ.Centers.News");
                      bodyFormData.append("ParentId", "NTROOT0");
                      bodyFormData.append("PostName", "");
                      bodyFormData.append("Pno", "3");
                      axios({
                        method: "post",
                        url: "https://api2.yuniv.net:444/our_team/getPost",
                        data: bodyFormData,
                      })
                        .then(function (response) {
                          self.News = response.data;
                          if (self.News.length != 0) {
                            self.showNews = true;
                          } else {
                            self.showNews = false;
                          }
                          var bodyFormData = new FormData();

                          bodyFormData.append("check", "getPost");
                          bodyFormData.append(
                            "Lang",
                            localStorage.getItem("lang")
                          );
                          bodyFormData.append("FbrnId", ID);
                          bodyFormData.append(
                            "Type",
                            "Univ.Centers.GalleryCategory"
                          );
                          bodyFormData.append("ParentId", "NTROOT0");
                          bodyFormData.append("PostName", "");
                          bodyFormData.append("Pno", "10");
                          axios({
                            method: "post",
                            url: "https://api2.yuniv.net:444/our_team/getPost",
                            data: bodyFormData,
                          })
                            .then(function (response) {
                              self.GalleryCategory = response.data;
                              if (self.GalleryCategory.length != 0) {
                                self.galleries(response.data[0]["postID"]);
                                self.showGallery = true;
                              } else self.showGallery = false;

                              document
                                .getElementById("VueMainJsNewTouch")
                                ?.remove();
                              let recaptchaScript = document.createElement(
                                "script"
                              );
                              recaptchaScript.setAttribute(
                                "src",
                                "/js/main.js"
                              );
                              recaptchaScript.id = "VueMainJsNewTouch";
                              document.head.appendChild(recaptchaScript);

                              setTimeout(() => {
                                self.show = false;
                                document
                                  .querySelectorAll("div.loader")
                                  .forEach(function (element) {
                                    element.setAttribute("hidden", true);
                                  });
                              }, 100);
                            })
                            .catch(function (response) {
                              // eslint-disable-next-line no-console
                              console.log("error", response);
                            });
                        })
                        .catch(function (response) {
                          // eslint-disable-next-line no-console
                          console.log("error", response);
                        });
                    })
                    .catch(function (response) {
                      // eslint-disable-next-line no-console
                      console.log("error", response);
                    });
                })
                .catch(function (response) {
                  // eslint-disable-next-line no-console
                  console.log("error", response);
                });
            })
            .catch(function (response) {
              // eslint-disable-next-line no-console
              console.log("error", response);
            });
        })
        .catch(function (response) {
          // eslint-disable-next-line no-console
          console.log("error", response);
        });
    },
  },
  mounted() {
    window.scroll(0, 0);
    var self = this;

    if (localStorage.getItem("lang") == "Ar") {
      self.translate = self.ArTranslat;
      document.getElementById("langfilewebsites")?.remove();
    } else {
      self.translate = self.EnTranslat;
      let link1 = document.createElement("link");
      link1.setAttribute("rel", "stylesheet");
      link1.id = "langfilewebsites";
      link1.setAttribute("href", "../../style-ltr.css");
      document.head.appendChild(link1);
    }
    self.CenterNews = self.translate[0]["Home"]["CenterNews"];
    self.CenterDept = self.translate[0]["Home"]["CollegeActivity"];
    self.BtnMore = self.translate[0]["Home"]["BtnDetials"];
    self.PhotoBrowser = self.translate[0]["Home"]["PhotoBrowser"];
    self.Detials = self.translate[0]["Home"]["Detials"];
    self.getApis();
  },
};
</script>
<style scoped>
.newsletter-area {
  background: #0c3ef7 none repeat scroll 100% 0;
  color: #ffffff;
  position: relative;
}

.newsletter-form {
  overflow: hidden;
  padding: 7px 0;
}

.newsletter-area:before {
  height: 65px;
  right: 0;
  left: auto;
  top: -13px;
  width: 27%;
}

.newsletter-area:after {
  border-width: 32.5px 27px;
  right: 27%;
  left: auto;
  top: -13px;
}

.newsletter-content {
  padding-top: 0px;
  z-index: 9;
  position: relative;
}

.ticker-left {
  display: inline-block;
  font-weight: bold;
  min-width: 50%;
  margin-top: 0px;
  margin-bottom: -1rem !important;
}

.single-event-image span {
  display: block;
  font-family: "montserratsemi_bold";
  font-size: 26px;
  text-align: center;
}
.imagesNews_ {
  width: 236px;
  height: 234px;
}

.c-text a {
  cursor: pointer;
}
.ImagesFID {
  width: 100% !important;
}
.about-area h3 {
  font-size: 30px;
  margin-bottom: 79px;
  padding-top: 0px;
  z-index: 9;
  position: relative;
}
.about-area:before {
  height: 79px;
  right: 0;
  left: auto;
  top: -32px;
  width: 49.2%;
}
.contact-text p {
  background: #0c3ef7;
  padding: 11px;
  color: #fff;
  border-radius: 10px;
}
.contact-text p span a {
  color: #fff;
}
.single-item-text {
  padding: 15px;
  height: auto;
}
.course-details-content {
  margin: 0;
}
.single-item-text h5 {
  font-weight: bold;
}
.single-item-text p {
  margin-top: 10px;
  text-align: justify;
  line-height: 30px;
}
.about-container p {
  color: #fff;
  line-height: 35px;
}
.single-course-details {
  padding: 10px 0 !important;
  margin-bottom: 0;
  box-shadow: -1px 8px 15px 5px #ccc;
}
.about-container h3 {
  color: #000;
}
.header-logo-menu.stick.semi-transparent,
.gallery-img,
.gallery-img .hover-effect,
.single-teacher-image > a:after {
  background: rgb(52 152 219 / 63%) none repeat scroll 0 0;
}
#gelery {
  padding: 10px;
}
#groupImages {
  background: #0c3ef7;
  padding: 11px;
  color: #fff;
  border-radius: 30px;
}
.btn-warning {
  text-align: center;
  margin-top: 30px;
}
</style>
